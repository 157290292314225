<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4 mr-2">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary ml-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>

                  <button
                    v-tooltip.hover
                    :title="
                      invalid || news.id === ''
                        ? 'Sie müssen die News zuerst speichern, bevor Sie eine Pushnotification senden können'
                        : 'Pushnotification senden'
                    "
                    class="btn btn-primary ml-2"
                    :disabled="invalid || news.id === ''"
                    @click="sendFCM"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-bell"
                      class="mr-2"
                    />Pushnotification senden
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="editable"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="id != ''" class="row col-xl-12 pr-0">
          <div class="col-xl-12 pr-4">
            <container-headline
              :headline="'Empfänger'"
              :col="3"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <button
                      class="btn btn-primary ml-2"
                      @click="oeffneVeranstaltungSelectModal"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-2"
                      />Personen von Veranstaltungen hinzufügen
                    </button>
                    <button
                      class="btn btn-primary ml-2"
                      @click="oeffneGPSucheModal"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-2"
                      />Einzelne Personen hinzufügen
                    </button>
                    <button
                      class="btn btn-primary ml-2"
                      @click="registerUsers"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-2"
                      />Personen bei App Registrieren
                    </button>
                  </div>
                  <div
                    class="row mt-3 mb-2"
                    v-if="
                      news.empfaenger &&
                      news.empfaenger.registered.token &&
                      news.empfaenger.registered.token.length > 0
                    "
                  >
                    <div class="col-12 mb-2">
                      <div
                        class="w-100 text-bold border-bottom border-2 border-primary"
                      >
                        Empfänger ({{ news.empfaenger.registered.token.length }}) :
                      </div>
                    </div>
                    <div class="col-12 d-flex flex-wrap">
                      <div
                        class="m-1"
                        v-for="empfaengerToken in news.empfaenger.registered.token"
                        :key="empfaengerToken.personID"
                      >
                        <div
                          class="rounded p-2 d-flex align-items-center justify-content-between bg-info-light"
                          @mouseenter="setHoverId(empfaengerToken.personID)"
                          @mouseleave="resetHoverId()"
                          :class="getBackground(empfaengerToken.personID)"
                        >
                          <div class="mr-1">
                            {{ empfaengerToken.personName }}
                          </div>
                          <div
                            @click="entferneEmpfaengerToken(empfaengerToken)"
                          >
                            <font-awesome-icon
                              v-tooltip.hover
                              title="Empfaenger entfernen"
                              icon="fa-duotone fa-trash"
                              class="clickable"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row mt-3 mb-2"
                    v-if="
                      news.empfaenger &&
                      news.empfaenger.registered.no_token &&
                      news.empfaenger.registered.no_token.length > 0
                    "
                  >
                    <div class="col-12 mb-2">
                      <div
                        class="w-100 text-bold border-bottom border-2 border-primary"
                      >
                        Empfänger, für welche keine Push-Notification gesendet
                        werden kann ({{ news.empfaenger.registered.no_token.length }}) :
                      </div>
                    </div>
                    <div class="col-12 d-flex flex-wrap">
                      <div
                        class="m-1"
                        v-for="empfaengerOhneToken in news.empfaenger.registered.no_token"
                        :key="empfaengerOhneToken.personID"
                      >
                        <div
                          class="rounded p-2 d-flex align-items-center justify-content-between bg-danger-light"
                          @mouseenter="setHoverId(empfaengerOhneToken.personID)"
                          @mouseleave="resetHoverId()"
                          :class="getBackground(empfaengerOhneToken.personID)"
                        >
                          <div class="mr-1">
                            {{ empfaengerOhneToken.personName }}
                          </div>
                          <div
                            @click="
                              entferneEmpfaengerOhneToken(empfaengerOhneToken)
                            "
                          >
                            <font-awesome-icon
                              v-tooltip.hover
                              title="Empfaenger entfernen"
                              icon="fa-duotone fa-trash"
                              class="clickable"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                                  <div
                    class="row mt-3 mb-2"
                    v-if="
                      news.empfaenger &&
                      news.empfaenger.not_registered.user &&
                      news.empfaenger.not_registered.user.length > 0
                    "
                  >
                    <div class="col-12 mb-2">
                      <div
                        class="w-100 text-bold border-bottom border-2 border-primary"
                      >
                        Empfänger, welche nicht bei der App registriert sind ({{ news.empfaenger.not_registered.user.length }}) :
                      </div>
                    </div>
                    <div class="col-12 d-flex flex-wrap">
                      <div
                        class="m-1"
                        v-for="empfaengerOhneToken in news.empfaenger.not_registered.user"
                        :key="empfaengerOhneToken.personID"
                      >
                        <div
                          class="rounded p-2 d-flex align-items-center justify-content-between bg-danger-light"
                          @mouseenter="setHoverId(empfaengerOhneToken.personID)"
                          @mouseleave="resetHoverId()"
                          :class="getBackground(empfaengerOhneToken.personID)"
                        >
                          <div class="mr-1">
                            {{ empfaengerOhneToken.personName }}
                          </div>
                          <div
                            @click="
                              entferneEmpfaengerOhneReg(empfaengerOhneToken)
                            "
                          >
                            <font-awesome-icon
                              v-tooltip.hover
                              title="Empfaenger entfernen"
                              icon="fa-duotone fa-trash"
                              class="clickable"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row col-xl-12 pr-0">
          <div class="col-xl-12 pr-4">
            <container-headline
              :headline="'Inhalt'"
              :col="3"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <input
                            v-model.trim="news.titel"
                            class="form-control"
                            :class="{ 'border-danger': !passed }"
                            placeholder="Titel"
                            :readonly="!editable"
                            :tabindex="1"
                          />
                          <label :class="{ 'text-danger': !passed }">{{
                            $t("global.title")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <input
                            v-model.trim="news.beschreibung"
                            class="form-control"
                            :class="{ 'border-danger': !passed }"
                            placeholder="Beschreibung"
                            :readonly="!editable"
                            :tabindex="2"
                          />
                          <label :class="{ 'text-danger': !passed }">{{
                            $t("global.description")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <v-select
                          v-model="news.tags"
                          label="bezeichnung"
                          :options="newstags"
                          :disabled="!editable"
                          placeholder="Tags"
                          multiple
                          :tabindex="3"
                        >
                          <span slot="no-options">Zum Suchen Tippen</span>
                        </v-select>
                        <label>Tags</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="news.datum"
                            v-model="news.datum"
                            placeholder="Datum"
                            :show-icon="true"
                            :disabled="!editable"
                            :tabindex="4"
                            :passed="passed"
                            @update="(val) => (news.datum = val)"
                          />
                          <label :class="{ 'text-danger': !passed }">{{
                            $t("global.date")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                    <!--
                    <div class="col-xl-1">
                      <div class="form-group">
                        <b-form-checkbox
                          :tabindex="5"
                          v-model="news.pushnotification"
                        ></b-form-checkbox>
                        <label>Push-Notification</label>
                      </div>
                    </div>
					-->
                  </div>
                  <div class="row mb-3">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <ckeditor
                            :editor="editor"
                            :disabled="!editable"
                            v-model="news.inhalt"
                            :config="editorConfig"
                            :tabindex="6"
                          ></ckeditor>
                          <label :class="{ 'text-danger': !passed }"
                            >Inhalt</label
                          >
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12">
                      <button
                        class="btn btn-primary"
                        @click="oeffneNewsDokumentUploadModal"
                      >
                        <font-awesome-icon
                          class="mr-2"
                          icon="fa-duotone fa-file-import"
                        />
                        <span>Dokument hochladen</span>
                      </button>
                    </div>
                  </div>
                  <div class="row" v-if="news.dokumente.length > 0">
                    <div class="col-12 d-flex flex-wrap">
                      <div
                        v-for="(dokument, index) in news.dokumente"
                        :key="'dokument-' + index"
                        class="rounded bg-medium-light p-3 my-2 mr-2 d-flex align-items-center"
                      >
                        <h6 class="mb-0 mr-4">{{ dokument.bezeichnung }}</h6>
                        <font-awesome-icon
                          v-tooltip.hover
                          title="Dokument anzeigen"
                          class="fs-18 clickable mr-2"
                          icon="fa-duotone fa-file"
                          @click="oeffneDokument(dokument.dokument)"
                        />
                        <font-awesome-icon
                          v-tooltip.hover
                          title="Dokument entfernen"
                          class="fs-18 clickable"
                          icon="fa-duotone fa-trash"
                          @click="entferneDokument(dokument)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>
    <change-watcher-alert />
    <loeschen-modal id="news-loeschen-modal" @confirmed="loeschen" />
    <veranstaltung-select-modal
      id="veranstaltung-select-modal-news"
      @confirmed="onVSResult"
    ></veranstaltung-select-modal>
    <gpSucheModal
      id="gp-suche-modal-news"
      @confirmed="onGPResult"
      remove
    ></gpSucheModal>
    <news-dokument-upload-modal
      id="news-dokument-upload-modal"
      :newsId="news.id"
    />
    <pdf-layer
      id="pdf-layer-news"
      ref="pdfLayerNews"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    />
  </div>
</template>
  
  <script>
import page from "@/mixins/Page";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import { textNotLinked } from "@/defaultTexts.js";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import gpSucheModal from "@/components/Geschaeftspartner/gpSucheModal";
import VeranstaltungSelectModal from "@/components/Modals/VeranstaltungSelectModal.vue";
import NewsDokumentUploadModal from "@/components/Modals/NewsDokumentUploadModal.vue";

import Api from "@/Api";
import FCM from "@/FCM";
import server from "@/server";

import { LADE_AUSWAHLWERTE_NEWS } from "@/store/news/actions.type";

import ChangeWatcher from "@/mixins/ChangeWatcher";
import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "@/components/Datepicker";

export default {
  name: "News",
  components: {
    HeadMenu,
    ContainerHeadline,
    Navbar,
    LoadingOverlay,
    ChangeWatcherAlert,
    DatePicker,
    LoeschenModal,
    VeranstaltungSelectModal,
    gpSucheModal,
    NewsDokumentUploadModal,
  },
  mixins: [page, ChangeWatcher],
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hoveredId: null,
      pdfPath: null,
      news: {
        id: "",
        titel: "",
        beschreibung: "",
        inhalt: "",
        pushnotification: false,
        datum: "",
        tags: [],
        dokumente: [
          //   {
          //     bezeichnung: "TestPDF.pdf",
          //     id: "18770050DBC",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "TestPDF.pdf",
          //     id: "18770050DBC1",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "TestPDF.pdf",
          //     id: "18770050DBC2",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "TestPDFTestPDF.pdf",
          //     id: "18770050DBC3",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "TestPDFTestPDFTestPDF.pdf",
          //     id: "18770050DBC4",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "TestPDFTestPDFTestPDFTestPDFTestPDFTestPDF.pdf",
          //     id: "18770050DBC5",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "TestPDFTestPDFTestPDF.pdf",
          //     id: "18770050DBC6",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "TestPDF.pdf",
          //     id: "18770050DBC7",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "TestPDF.pdf",
          //     id: "18770050DBC8",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "TestPDFTestPDFTestPDF.pdf",
          //     id: "18770050DBC9",
          //     dokument: "/files/18770050DC0/TestPDF.pdf",
          //   },
          //   {
          //     bezeichnung: "Testbild.jpg",
          //     id: "187701A2819",
          //     dokument: "/files/187701A281D/Testbild.jpg",
          //   },
        ],
        empfaenger: {
          registered: {
            token: [],
            no_token: [],
          },
          not_registered: {
            user: [],
          }

        },
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "insertTable",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    };
  },
  computed: {
    navbarTitel: function () {
      return "News";
    },
    newstags: {
      get() {
        return this.$store.state.news.newstags;
      },
    },
  },
  watch: {
    id: {
      handler: function (val) {
        if (val) {
          this.ladeNews(val);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.editable = this.berechtigungen.m_geschaeftspartner.update;
  },
  mounted() {
    if (this.newstags.length == 0)
      this.$store.dispatch(`news/${LADE_AUSWAHLWERTE_NEWS}`);
  },
  methods: {
    ladeNews(id) {
      Api.get("news/", {
        params: { id: id },
      }).then((response) => {
        this.news = response.data;
        // this.news.empfaenger.token = [
        //   { personName: "Lars Gutermann", id: "a1" },
        //   { personName: "Heinrich Mittelgutermann", id: "a2" },
        //   { personName: "Johann Gehtsomann", id: "a3" },
        //   { personName: "Friedrich Schlechtermann", id: "a4" },
        //   { personName: "Majoran Kräutermann", id: "a5" },
        //   { personName: "Peter Silie", id: "a6" },
        //   { personName: "Tim Yan", id: "a7" },
        //   { personName: "Jan van Klabautermann", id: "a8" },
        //   { personName: "Kurt Frischkäse", id: "a9" },
        //   { personName: "Helga Wurstfinger", id: "a10" },
        //   { personName: "Hans-Peter Schlabberhosen", id: "a11" },
        //   { personName: "Ingrid Gummibärli", id: "a12" },
        //   { personName: "Frieda Gugelhupf", id: "a13" },
        //   { personName: "Klaus Butterbrot", id: "a14" },
        //   { personName: "Gisela Schnitzeljagd", id: "a15" },
        //   { personName: "Rudi Bratwurst", id: "a16" },
        //   { personName: "Erika Zuckerschnute", id: "a17" },
        //   { personName: "Willi Käsekrainer", id: "a18" },
        //   { personName: "Heidi Kartoffelbrei", id: "a19" },
        //   { personName: "Fritz Wurstsalat", id: "a20" },
        //   { personName: "Renate Käsekuchen", id: "a21" },
        //   { personName: "Werner Nudelsuppe", id: "a22" },
        //   { personName: "Silke Schnitzelbank", id: "a23" },
        //   { personName: "Dieter Pommes Frites", id: "a24" },
        //   { personName: "Monika Spätzle", id: "a25" },
        //   { personName: "Karl-Heinz Sauerkraut", id: "a26" },
        //   { personName: "Gabi Gummibärchen", id: "a27" },
        //   { personName: "Otto Knödel", id: "a28" },
        // ];
        // this.news.empfaenger.no_token = [
        //   { personName: "Karl-Heinz Schwarz", id: "1111" },
        //   { personName: "Ingrid Bauer", id: "2222" },
        //   { personName: "Friedrich Müller", id: "3333" },
        //   { personName: "Gertrud Vogel", id: "4444" },
        //   { personName: "Egon Schneider", id: "5555" },
        //   { personName: "Renate Becker", id: "6666" },
        //   { personName: "Herbert Krause", id: "7777" },
        //   { personName: "Gudrun Wagner", id: "8888" },
        //   { personName: "Rüdiger Braun", id: "9999" },
        //   { personName: "Sabine Hoffmann", id: "0000" },
        //   { personName: "Wolfgang Schulz", id: "1237" },
        //   { personName: "Elisabeth Weber", id: "5678" },
        //   { personName: "Klaus-Dieter Fischer", id: "9876" },
        //   { personName: "Ingeborg Meyer", id: "5432" },
        //   { personName: "Dieter Schröder", id: "7890" },
        //   { personName: "Helga Schmidt", id: "4321" },
        //   { personName: "Rolf Richter", id: "6789" },
        //   { personName: "Helene Berger", id: "2345" },
        //   { personName: "Gunther Bachmann", id: "8901" },
        //   { personName: "Dagmar Petersen", id: "3456" },
        //   { personName: "Rudolf Kraus", id: "6780" },
        //   { personName: "Ursula Baumgartner", id: "1235" },
        //   { personName: "Franziska Fuchs", id: "5671" },
        //   { personName: "Hans-Dieter Wagenknecht", id: "9872" },
        //   { personName: "Adelheid Zimmermann", id: "5436" },
        // ];
      });
    },
    abbrechen() {
      this.$router.push({ name: "news-liste" });
    },
    sendFCM() {
      var headers = new Headers();
      headers.append(
        "Authorization",
        "key=AAAAm7N5UR0:APA91bGnbhMFulFU8rXhsVSvV4KR9DM_lL-0KKXP8-mBTS9kNF7klkrATUVw7M-Fzz3zgvxfszNKdBCM2B_lsZTzpbQG7-imnLWpVqYPMfJgye0wD5HO5Af8GtRozJhmUunoIyiejjjc"
      );
      headers.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        registration_ids: this.news.empfaenger.registered.token.map(tokenInfo => tokenInfo.token),
        notification: {
          body: this.news.beschreibung,
          title: this.news.titel,
          icon: "https://dev.bx-education.ch/pic/18184FEA33F_200x200.jpg",
          /*click_action:
            "https://scala.bx-education.ch/mobile-app/newsDetail/" + this.id,*/
        },
       /* android:{
        ttl:"10000s",
        notification: {
         click_action:"MainActivity"
       }
     },*/
      });

      var requestOptions = {
        method: "POST",
        headers: headers,
        body: raw,
        redirect: "follow",
      };

      fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.news);

      if (!this.id) {
        Api.post("news/", json, {})
          .then((response) => {
            this.news = response.data;
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "News erfolgreich gespeichert",
            });

            this.$router.replace({
              name: "news",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("news/", json, { params: { id: this.id } })
          .then((response) => {
            this.news = response.data;
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "News erfolgreich gespeichert",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    loeschen() {
      this.loading = true;

      Api.delete("news/", {
        data: [this.id],
      })
        .then(() => {
          this.$router.push({ name: "news-liste" });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "news-loeschen-modal");
    },

    oeffneVeranstaltungSelectModal() {
      $("#veranstaltung-select-modal-news").modal("show");
    },
    oeffneGPSucheModal() {
      $("#gp-suche-modal-news").modal("show");
    },
    onVSResult(veranstaltung) {
       let json = {
        event: veranstaltung[0].id
      }
      
        Api.put("news/", json, { params: { id: this.id } })
          .then((response) => {
            this.news = response.data;
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "News erfolgreich gespeichert",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
    },
    onGPResult(person) {
      let json = {
        gp: person[0].id
      }
      
        Api.put("news/", json, { params: { id: this.id } })
          .then((response) => {
            this.news = response.data;
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "News erfolgreich gespeichert",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
    },
    registerUsers() {
       let json = {
        user: this.news.empfaenger.not_registered.user.map(user => user.personID)
      }
      
        Api.post("news/createappuser/", json)
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "News erfolgreich gespeichert",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
    },
    setHoverId(id) {
      this.hoveredId = id;
    },
    resetHoverId() {
      this.hoveredId = null;
    },
    getBackground(id) {
      return this.hoveredId === id ? "hover" : "";
    },
    entferneEmpfaengerToken(empfaenger) {
      const indexToRemove = this.news.empfaenger.registered.token.findIndex(
        (empfaengerToken) => empfaengerToken.personID === empfaenger.personID
      );

      if (indexToRemove !== -1) {
        this.news.empfaenger.registered.token.splice(indexToRemove, 1);
      }
    },
    entferneEmpfaengerOhneToken(empfaenger) {
      const indexToRemove = this.news.empfaenger.registered.no_token.findIndex(
        (empfaengerOhneToken) =>
          empfaengerOhneToken.personID === empfaenger.personID
      );
      if (indexToRemove !== -1) {
        this.news.empfaenger.registered.no_token.splice(indexToRemove, 1);
      }
    },
    entferneEmpfaengerOhneReg(empfaenger) {
      const indexToRemove = this.news.empfaenger.not_registered.user.findIndex(
        (empfaengerOhneToken) =>
          empfaengerOhneToken.personID === empfaenger.personID
      );
      if (indexToRemove !== -1) {
        this.news.empfaenger.not_registered.user.splice(indexToRemove, 1);
      }
    },
    oeffneNewsDokumentUploadModal() {
      $("#news-dokument-upload-modal").modal("show");
    },
    dokumentHinzufuegen(dokument) {
      this.news.dokumente.push(dokument);
    },
    oeffneDokument(dokumentPfad) {
      this.pdfPath = Api.defaults.defaultURL + dokumentPfad;
      this.$refs.pdfLayerNews.show();
    },
    entferneDokument(dokument) {
      Api.delete("news/dokument/", { params: { id: dokument.id } }).then(() => {
        const indexToRemove = this.news.dokumente.indexOf(dokument);

        if (indexToRemove !== -1) {
          this.news.dokumente.splice(indexToRemove, 1);
        }
      });
    },
  },
};
</script>
  
  <style>
</style>
  